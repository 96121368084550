<template>
  <div class="header-wrapper">
    <div class="header container">
      <div class="header-top-menu flex spacer">
        <router-link to="/klantenservice/contact/">Contact</router-link>
        <router-link to="/klantenservice/support/">Support</router-link>
        <router-link to="/klantenservice/veel-gestelde-vragen/">Veel gestelde vragen</router-link>
      </div>
      <div class="header-content flex spacer">
        <div class="header-logo flex">
          <a href="/">
            <img src="../assets/images/logo.png">
          </a>
        </div>
        <div
          class="header-main-menu flex spacer"
        >
          <div class="header-main-menu-item">
            <router-link
              to="/"
              :class="[$route.path === '/' ? 'highlighted' : '']"
            >
              Home
            </router-link>
          </div>
          <div class="header-main-menu-item">
            <router-link
              to="/domeinnamen/"
              :class="[$route.path.includes('/domeinnamen/') ? 'highlighted' : '']"
            >
              Domeinnamen
            </router-link>
            <div class="header-main-menu-item-submenu">
              <router-link
                to="/domeinnamen/alle-domeinnaam-extensies/"
                :class="[$route.path.includes('/domeinnamen/alle-domeinnaam-extensies/') ? 'highlighted' : '']"
              >
                Alle Domeinnaam-extensies
              </router-link>
              <router-link
                to="/domeinnamen/prijzen-domeinnamen/"
                :class="[$route.path.includes('/domeinnamen/prijzen-domeinnamen/') ? 'highlighted' : '']"
              >
                Prijzen domeinnamen
              </router-link>
              <router-link
                to="/domeinnamen/domeinnaam-doorsturen/"
                :class="[$route.path.includes('/domeinnamen/domeinnaam-doorsturen/') ? 'highlighted' : '']"
              >
                Domeinnaam doorsturen
              </router-link>
              <router-link
                to="/domeinnamen/domeinnaam-overdragen/"
                :class="[$route.path.includes('/domeinnamen/domeinnaam-overdragen/') ? 'highlighted' : '']"
              >
                Domeinnaam overdragen
              </router-link>
              <router-link
                to="/domeinnamen/domeinnaam-verhuizen/"
                :class="[$route.path.includes('/domeinnamen/domeinnaam-verhuizen/') ? 'highlighted' : '']"
              >
                Domeinnaam verhuizen
              </router-link>
            </div>
          </div>
          <div class="header-main-menu-item">
            <router-link
              to="/webhosting/"
              :class="[$route.path.includes('/webhosting/') ? 'highlighted' : '']"
            >
              Webhosting
            </router-link>
            <div class="header-main-menu-item-submenu">
              <router-link
                to="/webhosting/webhosting-small/"
                :class="[$route.path.includes('/webhosting/webhosting-small/') ? 'highlighted' : '']"
              >
                Webhosting Small
              </router-link>
              <router-link
                to="/webhosting/webhosting-medium/"
                :class="[$route.path.includes('/webhosting/webhosting-medium/') ? 'highlighted' : '']"
              >
                Webhosting Medium
              </router-link>
              <router-link
                to="/webhosting/webhosting-large/"
                :class="[$route.path.includes('/webhosting/webhosting-large/') ? 'highlighted' : '']"
              >
                Webhosting Large
              </router-link>
            </div>
          </div>
          <div class="header-main-menu-item">
            <router-link
              to="/ssl-certificaten/"
              :class="[$route.path.includes('/ssl-certificaten/') ? 'highlighted' : '']"
            >
              SSL-certificaten
            </router-link>
            <div class="header-main-menu-item-submenu">
              <router-link
                to="/ssl-certificaten/standaard-ssl-certificaat/"
                :class="[$route.path.includes('/ssl-certificaten/standaard-ssl-certificaat/') ? 'highlighted' : '']"
              >
                Standaard SSL-certificaat
              </router-link>
              <router-link
                to="/ssl-certificaten/ev-certificaat/"
                :class="[$route.path.includes('/ssl-certificaten/ev-certificaat/') ? 'highlighted' : '']"
              >
                EV SSL-certificaat
              </router-link>
              <router-link
                to="/ssl-certificaten/multi-domein-ssl-certificaat/"
                :class="[$route.path.includes('/ssl-certificaten/multi-domein-ssl-certificaat/') ? 'highlighted' : '']"
              >
                Multi-domein SSL-certificaat
              </router-link>
              <router-link
                to="/ssl-certificaten/wildcard-ssl-certificaat/"
                :class="[$route.path.includes('/ssl-certificaten/wildcard-ssl-certificaat/') ? 'highlighted' : '']"
              >
                Wildcard SSL-certificaat
              </router-link>
              <router-link
                to="/ssl-certificaten/overige-ssl-certificaten/"
                :class="[$route.path.includes('/ssl-certificaten/overige-ssl-certificaten/') ? 'highlighted' : '']"
              >
                Overige type SSL-certificaten
              </router-link>
            </div>
          </div>
          <div class="header-main-menu-item">
            <router-link
              to="/cloud-server-hosting-vps-hosting/"
              :class="[$route.path.includes('/cloud-server-hosting-vps-hosting/') ? 'highlighted' : '']"
            >
              Cloud Servers
            </router-link>
            <div class="header-main-menu-item-submenu">
              <router-link
                to="/cloud-server-hosting-vps-hosting/vps-1/"
                :class="[$route.path.includes('/cloud-server-hosting-vps-hosting/vps-1/') ? 'highlighted' : '']"
              >
                Cloud Server – Minimal
              </router-link>
              <router-link
                to="/cloud-server-hosting-vps-hosting/vps-2/"
                :class="[$route.path.includes('/cloud-server-hosting-vps-hosting/vps-2/') ? 'highlighted' : '']"
              >
                Cloud Server – Starter
              </router-link>
              <router-link
                to="/cloud-server-hosting-vps-hosting/vps-3/"
                :class="[$route.path.includes('/cloud-server-hosting-vps-hosting/vps-3/') ? 'highlighted' : '']"
              >
                Cloud Server – Average
              </router-link>
              <router-link
                to="/cloud-server-hosting-vps-hosting/vps-heavy/"
                :class="[$route.path.includes('/cloud-server-hosting-vps-hosting/vps-heavy/') ? 'highlighted' : '']"
              >
                Cloud Server – Heavy
              </router-link>
            </div>
          </div>
          <div class="spacer"></div>
          <div class="header-main-menu-item">
            <router-link
              to="/klantenportaal/"
              :class="[$route.path.includes('/klantenportaal/') ? 'highlighted' : '']"
            >
              Mijn Hosting on Demand
            </router-link>
            <div class="header-main-menu-item-submenu">
              <a href="https://cp.hostingondemand.nl">
                Inloggen klantenpaneel
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
}
</script>

<style lang="scss" scoped>
.header-wrapper {
  padding-top: 10px;
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  min-height: 110px;
  border-bottom: 1px solid var(--primary-dark);
  font-family: 'Roboto Slab', serif;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;

    .header-top-menu {
      justify-content: flex-end;
      flex-wrap: wrap;
      width: 100%;

      a {
        font-size: 13px;
        line-height: 16px;
      }
      a:hover {
        color: var(--highlight);
      }
      a:not(:last-child) {
        margin-right: 10px;
      }
    }

    .header-content {
      flex-wrap: wrap;
      width: 100%;

      .header-logo {
        margin-right: 40px;
        margin-bottom: 20px;
      }

      .header-main-menu {
        align-items: flex-end;
        flex-wrap: wrap;

        .header-main-menu-item {
          position: relative;
          height: 50px;
          display: flex;
          align-items: flex-end;

          a {
            padding-left: 10px;
            margin-bottom: 20px;
            color: var(--primary-dark);
            font-size: 16px;
            line-height: 21px;
            white-space: nowrap;

            &.highlighted {
              color: var(--highlight);
            }
          }
          a:hover {
            color: var(--highlight);
          }

          .header-main-menu-item-submenu {
            position: absolute;
            padding: 10px;
            display: none;
            background-color: var(--background);
            top: 50px;
            flex-direction: column;
            border: 1px solid var(--primary-dark);
            border-top: unset;
            z-index: 1;

            a[href] {
              cursor: pointer;
              padding: 0 10px;
              margin-bottom: 0px;
              line-height: 30px;
              border-right: none;
            }
            a:last-child {
              margin-bottom: 0;
            }
          }
        }
        .header-main-menu-item:hover {
          .header-main-menu-item-submenu {
            display: flex;
          }
        }
        .header-main-menu-item:not(:last-child) {
          a {
            padding-right: 10px;
            border-right: 1px solid var(--primary-dark);
          }
        }
      }
    }
  }
}
</style>