<template>
  <div class="app">
    <Header/>
    <router-view></router-view>
    <Footer/>
  </div>
</template>

<script>
import { provide, watch } from 'vue';
import { useRoute } from 'vue-router';
import data from '@/store/data';

import Header from './components/Header.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    Header,
    Footer,
  },
  setup() {
    const route = useRoute();

    provide('data', data);

    watch(
      () => route.meta.documentTitle,
      async newDocumentTitle => {
        document.title = newDocumentTitle || 'Onbekende pagina';
      },
    );
  },
}
</script>

<style lang="scss">
.app {

}
</style>
