<template>
  <div class="expansion-panels">
    <div
      v-for="(title, index) in panels"
      :key="index"
      class="expansion-panel"
      :class="[
        opened === index ? 'open' : '',
        opening === index ? 'opening' : '',
        closing === index ? 'closing' : '',
      ]"
    >
      <div
        class="header-wrapper"
        @click="toggle(index)"
      >
        <slot :name="`header-${index}`">
          <div class="header">
            {{ title }}
          </div>
        </slot>
      </div>
      <div
        :ref="el => { panelRefs[index] = el }"
        class="content-wrapper"
      >
        <slot :name="`content-${index}`">
          <div class="content">
            <slot :name="`content-text-${index}`"></slot>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onBeforeUpdate } from 'vue';

export default {
  name: 'ExpansionPanels',
  props: {
    panels: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const panelRefs = ref([]);
    onBeforeUpdate(() => {
      panelRefs.value = [];
    });

    // opening and closing logic
    let opened = ref(-1);
    let opening = ref(-1);
    let closing = ref(-1);

    function toggle(index) {
      // remember currently open panel
      const prevOpened = opened.value;

      // if new panel is being opened: set it as opening and set currently open as closing
      if (opened.value !== index) {
        opening.value = index;
        closing.value = opened.value;
      }
      // else means that currently open panel should be closed
      else closing.value = index;
      // in any case there is no opened panel now, only opening and/or closing
      opened.value = -1;

      // if there is a closing panel: set it height to 0
      if (closing.value !== -1) {
        panelRefs.value[closing.value].style.height = '';
      }
      // if there is opening panel: set its height to a height of its child
      if (opening.value !== -1) {
        panelRefs.value[opening.value].style.height = `${panelRefs.value[opening.value].children[0].offsetHeight}px`;
      }

      // after animation is done
      setTimeout(() => {
        // if there was a closing panel: remove height styling
        if (closing.value !== -1) {
          panelRefs.value[closing.value].style.height = '';
        }
        // if there was an opening panel: remove height styling
        if (opening.value !== -1) {
          panelRefs.value[opening.value].style.height = '';
        }
        // reset oepning and closing indexes to -1
        opening.value = -1;
        closing.value = -1;

        // if previously opened panel is not the one that should be opened now set new one as opened
        if (prevOpened !== index) {
          opened.value = index;
          panelRefs.value[opened.value].style.height = `${panelRefs.value[opened.value].children[0].offsetHeight}px`;
        }
      }, 350);
    }

    return {
      // refs
      panelRefs,

      // data
      opened,
      opening,
      closing,

      // methods
      toggle,
    }
  }
}
</script>

<style lang="scss" scoped>
.expansion-panels {
  .expansion-panel {
    overflow: hidden;
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);

    .header-wrapper {
      cursor: pointer;

      .header {
        padding: 10px 15px;
        background-color: var(--panel);
        color: var(--highlight);
        border: 1px solid var(--border);
        border-radius: 4px;
        font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
        font-weight: normal;
        font-size: 13px;
        line-height: 1.1;
      }
      .header:hover {
        color: var(--primary);
      }
    }

    .content-wrapper {
      height: 0px;
      transition: height .35s ease;

      .content {
        padding: 15px;
        background-color: var(--background);
        border: 1px solid var(--border);
        border-radius: 0 0 4px 4px;
      }
    }
  }

  .opening, .closing {
    .content-wrapper {
      overflow: hidden;
    }
  }

  .open, .opening, .closing {
    .header-wrapper {
      .header {
        border-radius: 4px 4px 0 0;
        border-bottom: hidden;
      }
    }
  }

  .expansion-panel:not(:last-child) {
    margin-bottom: 5px;
  }
}
</style>