import { createWebHistory, createRouter } from 'vue-router';

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import('@/pages/Home/Home.vue'),
    meta: {
      documentTitle: 'Domeinnamen, hosting & virtual servers | Hosting on Demand | Domeinnamen, hosting, virtual server: online gemak!',
      title: 'Home',
    },
  },
  {
    path: "/domeinnamen/",
    name: "domeinnamen",
    component: () => import('@/pages/DomainName/DomainName.vue'),
    meta: {
      documentTitle: 'Domeinnamen: online domeincheck | Hosting on Demand',
      title: 'Domeinnamen',
    },
  },
  {
    path: "/domeinnamen/alle-domeinnaam-extensies/",
    name: "alle-domeinnaam-extensies",
    component: () => import('@/pages/DomainName/AllDomainNameExtensions.vue'),
    meta: {
      documentTitle: 'Alle domeinnaam extensies registreren | Hosting on Demand',
      title: 'Alle Domeinnaam-extensies',
    },
  },
  {
    path: "/domeinnamen/prijzen-domeinnamen/",
    name: "prijzen-domeinnamen",
    component: () => import('@/pages/DomainName/DomainNamePrices.vue'),
    meta: {
      documentTitle: 'Prijzen van domeinnamen: lage kosten | Hosting on Demand',
      title: 'Prijzen domeinnamen',
    },
  },
  {
    path: "/domeinnamen/domeinnaam-doorsturen/",
    name: "domeinnaam-doorsturen",
    component: () => import('@/pages/DomainName/ForwardDomainName.vue'),
    meta: {
      documentTitle: 'Domeinnaam doorsturen: doorverwijzen | Hosting on Demand',
      title: 'Domeinnaam doorsturen: doorverwijzen naar een andere website',
    },
  },
  {
    path: "/domeinnamen/domeinnaam-overdragen/",
    name: "domeinnaam-overdragen",
    component: () => import('@/pages/DomainName/TransferDomainNameTakeOverWithCode.vue'),
    meta: {
      documentTitle: 'Domeinnaam overdragen: overnemen met code | Hosting on Demand',
      title: 'Domeinnaam overdragen: overnemen met code',
    },
  },
  {
    path: "/domeinnamen/domeinnaam-verhuizen/",
    name: "domeinnaam-verhuizen",
    component: () => import('@/pages/DomainName/TransferDomainNameToAnotherProvider.vue'),
    meta: {
      documentTitle: 'Domeinnaam verhuizen naar een andere provider | Hosting on Demand',
      title: 'Domeinnaam verhuizen: naar een andere provider',
    },
  },
  {
    path: "/webhosting/",
    name: "webhosting",
    component: () => import('@/pages/Webhosting/Webhosting.vue'),
    meta: {
      documentTitle: 'Webhosting: de beste webhosting voor de laagste prijs | Hosting on Demand',
      title: 'Webhosting',
    },
  },
  {
    path: "/webhosting/webhosting-small/",
    name: "webhosting-small",
    component: () => import('@/pages/Webhosting/WebhostingSmall.vue'),
    meta: {
      documentTitle: 'Webhosting Small: goedkope domein hosting | Hosting on Demand',
      title: 'Webhosting Small: goedkope shared hosting',
    },
  },
  {
    path: "/webhosting/webhosting-medium/",
    name: "webhosting-medium",
    component: () => import('@/pages/Webhosting/WebhostingMedium.vue'),
    meta: {
      documentTitle: 'Webhosting Large: hosting voor WordPress | Hosting on Demand',
      title: 'Webhosting Medium: hosting voor WordPress',
    },
  },
  {
    path: "/webhosting/webhosting-large/",
    name: "webhosting-large",
    component: () => import('@/pages/Webhosting/WebhostingLarge.vue'),
    meta: {
      documentTitle: 'Webhosting Large: hosting voor WordPress | Hosting on Demand',
      title: 'Webhosting Large: hosting voor WordPress',
    },
  },
  {
    path: "/ssl-certificaten/",
    name: "ssl-certificaten",
    component: () => import('@/pages/SSLCertificates/SSLCertificates.vue'),
    meta: {
      documentTitle: 'SSL certificaten: 100% beveiliging | Hosting on Demand',
      title: 'SSL-certificaten',
    },
  },
  {
    path: "/ssl-certificaten/standaard-ssl-certificaat/",
    name: "standaard-ssl-certificaat",
    component: () => import('@/pages/SSLCertificates/StandardSSLCertificate.vue'),
    meta: {
      documentTitle: 'Standaard SSL certificaat | Hosting on Demand',
      title: 'Standaard SSL-certificaat',
    },
  },
  {
    path: "/ssl-certificaten/ev-certificaat/",
    name: "ev-certificaat",
    component: () => import('@/pages/SSLCertificates/EVSSLCertificate.vue'),
    meta: {
      documentTitle: 'EV certificaat kopen: 100% veilig | Hosting on Demand',
      title: 'Extended Validation (EV) SSL-certificaat',
    },
  },
  {
    path: "/ssl-certificaten/multi-domein-ssl-certificaat/",
    name: "multi-domein-ssl-certificaat",
    component: () => import('@/pages/SSLCertificates/MultidomainSSLCertificate.vue'),
    meta: {
      documentTitle: 'Multi-domein SSL certificaat | Hosting on Demand',
      title: 'Multi-domein SSL-certificaat',
    },
  },
  {
    path: "/ssl-certificaten/wildcard-ssl-certificaat/",
    name: "wildcard-ssl-certificaat",
    component: () => import('@/pages/SSLCertificates/WildcardSSLCertificaat.vue'),
    meta: {
      documentTitle: 'Wildcard SSL certificaat | Hosting on Demand',
      title: 'Wildcard SSL-certificaat',
    },
  },
  {
    path: "/ssl-certificaten/overige-ssl-certificaten/",
    name: "overige-ssl-certificaten",
    component: () => import('@/pages/SSLCertificates/OtherTypeSSLCertificates.vue'),
    meta: {
      documentTitle: 'Overige SSL certificaten | Hosting on Demand',
      title: 'Overige type SSL-certificaten',
    },
  },
  {
    path: "/cloud-server-hosting-vps-hosting/",
    name: "cloud-server-hosting-vps-hosting",
    component: () => import('@/pages/CloudServers/CloudServers.vue'),
    meta: {
      documentTitle: 'Cloud Server Hosting of VPS Hosting | Hosting on Demand',
      title: 'Cloud Server hosting / VPS hosting, stabiel en flexibel',
    },
  },
  {
    path: "/cloud-server-hosting-vps-hosting/vps-1/",
    name: "vps-1",
    component: () => import('@/pages/CloudServers/VPSMinimal.vue'),
    meta: {
      documentTitle: 'VPS Minimal: 10GB storage 25GB dataverkeer | Hosting on Demand',
      title: 'VPS Minimal',
    },
  },
  {
    path: "/cloud-server-hosting-vps-hosting/vps-2/",
    name: "vps-2",
    component: () => import('@/pages/CloudServers/VPSStarter.vue'),
    meta: {
      documentTitle: 'VPS Starter: 25GB storage 50GB dataverkeer | Hosting on Demand',
      title: 'VPS Starter',
    },
  },
  {
    path: "/cloud-server-hosting-vps-hosting/vps-3/",
    name: "vps-3",
    component: () => import('@/pages/CloudServers/VPSAverage.vue'),
    meta: {
      documentTitle: 'VPS Average: 50GB storage 100GB dataverkeer | Hosting on Demand',
      title: 'VPS Average',
    },
  },
  {
    path: "/cloud-server-hosting-vps-hosting/vps-heavy/",
    name: "vps-heavy",
    component: () => import('@/pages/CloudServers/VPSHeavy.vue'),
    meta: {
      documentTitle: 'VPS Heavy: 100GB storage 250GB dataverkeer | Hosting on Demand',
      title: 'VPS Heavy',
    },
  },
  {
    path: "/klantenportaal/",
    name: "klantenportaal",
    component: () => import('@/pages/Other/CustomerPortal.vue'),
    meta: {
      documentTitle: 'Klantenportaal - Hosting On Demand',
      title: 'Klantenportaal',
    },
  },
  {
    path: "/klantenservice/",
    name: "klantenservice",
    component: () => import('@/pages/CustomerService/CustomerService.vue'),
    meta: {
      documentTitle: 'Klantenservice: specialisten in hosting | Hosting on Demand',
      title: 'Klantenservice',
    },
  },
  {
    path: "/klantenservice/contact/",
    name: "contact",
    component: () => import('@/pages/CustomerService/Contact.vue'),
    meta: {
      documentTitle: 'Contact - Hosting On Demand',
      title: 'Contact',
    },
  },
  {
    path: "/klantenservice/support/",
    name: "support",
    component: () => import('@/pages/CustomerService/Support.vue'),
    meta: {
      documentTitle: 'Support: technisch specialisten | Hosting on Demand',
      title: 'Support',
    },
  },
  {
    path: "/klantenservice/veel-gestelde-vragen/",
    name: "veel-gestelde-vragen",
    component: () => import('@/pages/CustomerService/FrequentlyAskedQuestions.vue'),
    meta: {
      documentTitle: 'Veelgestelde vragen over hosting | Hosting on Demand',
      title: 'Veel gestelde vragen',
    },
  },
  {
    path: "/wat-is-dnssec-dns-security-extensions/",
    name: "wat-is-dnssec-dns-security-extensions",
    component: () => import('@/pages/Blog/WhatIsDnssecDnsSecurityExtensions.vue'),
    meta: {
      documentTitle: 'Wat is DNSSEC (DNS Security Extensions)? - Hosting On Demand',
      title: 'Wat is DNSSEC (DNS Security Extensions)?',
    },
  },
  {
    path: "/voorwaarden/",
    name: "voorwaarden",
    component: () => import('@/pages/Requirements/Requirements.vue'),
    meta: {
      documentTitle: 'Voorwaarden bij Hosting on Demand',
      title: 'Voorwaarden',
    },
  },
  {
    path: "/goedkope-hosting/",
    name: "goedkope-hosting",
    component: () => import('@/pages/Other/CheapHosting.vue'),
    meta: {
      documentTitle: 'Goedkope hosting | Hosting on Demand',
      title: 'Goedkope hosting',
    },
  },
  {
    path: "/domeinnaam-vrij/",
    name: "domeinnaam-vrij",
    component: () => import('@/pages/Other/DomainNameFree.vue'),
    meta: {
      documentTitle: 'Is mijn domeinnaam vrij? Direct checken | Hosting on Demand',
      title: 'Domeinnaam vrij',
    },
  },
  {
    path: "/goedkope-domeinnaam/",
    name: "goedkope-domeinnaam",
    component: () => import('@/pages/Other/CheapDomainName.vue'),
    meta: {
      documentTitle: 'Goedkope domeinnaam | Hosting on Demand',
      title: 'Goedkope domeinnaam',
    },
  },
  {
    path: "/notice-and-take-down-procedure-hostingondemand/",
    name: "notice-and-take-down-procedure-hostingondemand",
    component: () => import('@/pages/Other/NoticeAndTakeDownProcedure.vue'),
    meta: {
      documentTitle: 'Notice-and-Take-Down-procedure Hostingondemand - Hosting On Demand',
      title: 'Notice-and-Take-Down-procedure Hostingondemand',
    },
  },
  {
    path: "/verwerkersovereenkomst-hosting-on-demand/",
    name: "verwerkersovereenkomst-hosting-on-demand",
    component: () => import('@/pages/Other/DataProcessingAgreement.vue'),
    meta: {
      documentTitle: 'Verwerkersovereenkomst Hosting on Demand - Hosting On Demand',
      title: 'Verwerkersovereenkomst Hosting on Demand',
    },
  },
  {
    path: "/voorwaarden/disclaimer/",
    name: "disclaimer",
    component: () => import('@/pages/Other/Disclaimer.vue'),
    meta: {
      documentTitle: 'Disclaimer voor https://hostingondemand.nl - Hosting On Demand',
      title: 'Disclaimer voor https://hostingondemand.nl',
    },
  },
  {
    path: "/voorwaarden/privacy-policy/",
    name: "privacy-policy",
    component: () => import('@/pages/Other/PrivacyPolicy.vue'),
    meta: {
      documentTitle: 'Privacy Policy | Hosting on Demand',
      title: 'Privacy Policy',
    },
  },
  {
    path: "/voorwaarden/algemene-voorwaarden/",
    name: "algemene-voorwaarden",
    component: () => import('@/pages/Other/TermsAndConditions.vue'),
    meta: {
      documentTitle: 'Algemene voorwaarden Hosting on Demand - Hosting On Demand',
      title: 'Algemene voorwaarden Hosting on Demand',
    },
  },
  {
    path: "/dedicated-servers/",
    name: "dedicated-servers",
    component: () => import('@/pages/Other/DedicatedServers.vue'),
    meta: {
      documentTitle: 'Dedicated Servers: optimaal betrouwbaar | Hosting on Demand',
      title: 'Dedicated servers',
    },
  },
  {
    path: '/:catchAll(.*)',
    name: "pagina-niet-gevonden",
    component: () => import('@/pages/Other/PageNotFound.vue'),
    meta: {
      documentTitle: 'Pagina niet gevonden - Hosting On Demand',
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;