<template>
  <div class="footer-wrapper column">
    <div class="footer flex spacer container">
      <div class="footer-item">
        <div class="footer-item-title">Contact</div>
        <div class="footer-item-content contact">
          support@hostingondemand.nl
        </div>
      </div>
      <div class="footer-item">
        <div class="footer-item-title">Het laatste nieuws</div>
        <div class="footer-item-content news">
          <router-link to="/wat-is-dnssec-dns-security-extensions/">
            Wat is DNSSEC (DNS Security Extensions)?
          </router-link>
        </div>
      </div>
      <div class="footer-item">
        <div class="footer-item-title">Veel gestelde vragen</div>
        <div class="footer-item-content faq">
          <ExpansionPanels
            :panels="questions.map(q => '')"
          >
            <template
              v-for="(question, index) in questions"
              :key="index"
              v-slot:[questionHeaderSlotName(index)]
            >
              <div class="summary">{{ question.title }}</div>
            </template>
            <template
              v-for="(question, index) in questions"
              :key="index"
              v-slot:[questionContentSlotName(index)]
            >
              <div class="details" v-html="question.content"></div>
            </template>
          </ExpansionPanels>
          <router-link
            class="faq-link"
            to="/klantenservice/veel-gestelde-vragen/"
          >
            Alle veel gestelde vragen
          </router-link>
        </div>
      </div>
    </div>
    <div class="footer-bottom-menu flex container">
      <router-link to="/voorwaarden/disclaimer/">
        Disclaimer
      </router-link>
      <router-link to="/voorwaarden/algemene-voorwaarden/">
        Algemene voorwaarden
      </router-link>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';

import ExpansionPanels from '@/components/ExpansionPanels.vue';

export default {
  name: 'Footer',
  components: {
    ExpansionPanels,
  },
  setup() {
    const data = inject('data');
    const questions = data.staticData.questions.filter(q => q.tags.split(' ').includes('footer'));

    function questionHeaderSlotName(index) {
      return `header-${index}`;
    }

    function questionContentSlotName(index) {
      return `content-${index}`;
    }

    return {
      // static data
      questions,

      // methods
      questionHeaderSlotName,
      questionContentSlotName,
    };
  },
}
</script>

<style lang="scss" scoped>
.footer-wrapper {
  align-items: center;
  margin-top: 40px;
  padding: 20px 0;
  min-height: 600px;
  background-color: var(--primary-dark);

  .footer {
    .footer-item {
      flex: 1;
      margin: 0 15px;
      padding-bottom: 20px;
      min-height: 200px;
      height: fit-content;
      border-bottom: 1px dotted #ffffff;

      .footer-item-title {
        margin: 20px 0;
        color: var(--primary);
        font-size: 24px;
        font-family: 'Roboto Slab', serif;
        font-weight: 300;
      }

      .footer-item-content {
        color: var(--contrast-text);
        display: flex;
        flex-direction: column;

        a {
          margin-bottom: 20px;
        }

        .summary {
          padding: 10px 0;
          color: var(--highlight);
          list-style: none;
          cursor: pointer;
          font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
          font-size: 13px;
        }
        .summary:hover {
          color: var(--primary);
        }

        .details {
          padding: 15px;
          border-top: 1px solid white;
          transition:height 3s ease-in;
          font-size: 13px;

          p {
            margin-top: 0;
          }
        }

        .faq-link {
          background-color: var(--highlight);
          color: var(--contrast-text);
          margin-top: 20px;
          padding: 10px 16px;
          width: fit-content;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 18px;
          text-transform: uppercase;
          white-space: nowrap;
        }
        .faq-link:hover {
          background-color: var(--contrast-text);
          color: var(--primary-dark);
        }
      }
    }
  }

  .footer-bottom-menu {
    padding: 10px 0;
    a {
      padding: 10px;
      margin-bottom: 20px;
    }
  }
}
</style>